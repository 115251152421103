import ProsumerHome from "app/components/prosumer_home"
import setupLazyLoad from "app/scripts/setup_lazy_load"
import SetupUnifiedHeaderDropdown from "shared/scripts/setup_unified_header_dropdown"
import SetupUnifiedMobileMenu from "shared/scripts/setup_unified_mobile_menu"
import UnifiedProfileDropdown from "shared/components/unified_profile_dropdown"

Object.assign(window.App.Comps, {
  UnifiedProfileDropdown,
})

Object.assign(window.App.Scripts, {
  SetupUnifiedMobileMenu,
  ProsumerHome,
  SetupUnifiedHeaderDropdown,
  setupLazyLoad,
})
