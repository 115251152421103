export const pluralizeStepType = type => (type === "query" ? "queries" : `${type}s`)

export const singularFromPlural = type => (type === "queries" ? "query" : type.slice(0, -1))

export const fullModuleName = (channel_module_name, step_module_name) => `${channel_module_name}.${step_module_name}`

/**
 * For formatting an editable currency input field.
 * Examples of formatted values: "0", "1.", "2.3", "45.67", etc.
 * @param {string} value
 * @param {number} precision
 * @returns the formatted value
 */
export function formatPartialCurrencyValue(value, precision = 2) {
  const number = value.replace(/[^.\d]/g, "")
  const parts = number.split(".").slice(0, precision === 0 ? 1 : 2)
  if (parts[0].length) {
    // no leading 0s
    parts[0] = Number(parts[0])
  }
  if (parts[1]) {
    parts[1] = parts[1].substring(0, precision)
  }
  return parts.join(".")
}

export const gql = (literals, ...substitutions) => {
  let result = ""
  for (let i = 0; i < substitutions.length; i++) {
    result += literals[i]
    result += substitutions[i]
  }
  result += literals[literals.length - 1]
  return result
}

/**
 * Nondestructively adds an element to the end of an array.
 * Returns a new array with the element added. Doesn't modify the original array.
 * @returns {*[]}
 */
export const arrayAdd = (arr, newElement) => {
  return [...arr, newElement]
}

/**
 * Nondestructively replace an element in an array.
 * Returns a new array with the element replaced. Doesn't modify the original array.
 * @returns {*[]}
 */
export const arrayReplace = (arr, index, newElement) => {
  return [...arr.slice(0, index), newElement, ...arr.slice(index + 1)]
}

/**
 * Nondestructively remove an element from an array.
 * Returns a new array with the element removed. Doesn't modify the original array.
 * @returns {*[]}
 */
export const arrayRemove = (arr, index) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

export const gqlAliasStepModuleName = moduleName => moduleName?.replace(/-([a-z])/g, g => g[1].toUpperCase())

export const partition = (array, isValid) =>
  array.reduce(([pass, fail], elem) => (isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]), [[], []])

export function gtag(event_name, params) {
  // GA4 is being managed by Tag Manager. To use a custom tag, you should follow these steps:
  // 1 - Create a Trigger based on the custom event name.
  // 2 - Create variables related with the params that will be sent.
  // 3 - Create a Tag responsible to receive the trigger and send the event to GA4 with the variables.
  //
  // Based on that, we can't use the usual gtag() function. It's necessary to push the params first for the dataLayer and then push the event.
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(params)
  window.dataLayer.push({ event: event_name })
}

export const assignWithBustedCache = (maybeCachedUrl, maybeMetaKey = false) => {
  const url = new URL(maybeCachedUrl, window.location.origin)
  url.searchParams.set("_cb", new Date().getTime().toString())
  if (maybeMetaKey) {
    return window.open(url.toString(), "_blank")
  }

  window.location.assign(url.toString())
}
